export default [
    {
        image: "images/happy1.jpg",
        title: "Mükemmel evlilik, sonsuz aşk",
        description:
            "2007 Haziran Ayı’nda eşim Almanya’da yüksek lisans yapmakta, ben ise üniversite 2.sınıftaydım. Eşim Türkçe konuşacak ve yazışacak birilerine ihtiyaç duymuş, bense geçirdiğim küçük bir rahatsızlık sebebiyle bu arkadaşlık sitesinde fazlaca vakit harcayabilecek boş zamana sahip …",
        link: "//www.siberalem.com/mukemmel-evlilik-sonsuz-ask",
    },
    {
        image: "images/happy2.jpg",
        title: "Öylesine Mutluyuz Ki!",
        description:
            "Herşeyden ve herkesten umudumu kestiğim bir dönem yaşıyordum. Aradığım bir aşk değildi aslında, Siberalem arkadaşlık sitesinde yeni birini ya da birilerini tanıyıp farklı insanlar ve hayatlar keşfetmek isterken hayatıma yeni bir sayfa açacağımı düşünemezdim. Eşimle …",
        link: "//www.siberalem.com/oylesine-mutluyuz-ki",
    },
    {
        image: "images/happy3.jpg",
        title: "Siberalem’in Hayatımdaki Rolü Oskarlıktı!",
        description:
            "İlk mesaj ondan gelmişti. Beni etkileyen ilk şey de onun cesaretiydi. O aralar yeni bir ilişkiden sarsılarak çıkmıştım. Kendisine Siberalem Arkadaşlık Sitesi'nden mesaj attım ve cep numaramı yazdım. Hazır olmadığımı ve yeni bir ilişki için...",
        link: "//www.siberalem.com/siberalemin-hayatimdaki-rolu-oskarlikti",
    },
];
