// #region Global Imports
import { Dispatch } from "redux";
// #endregion Global Imports

// #region Local Imports
import { MembershipType } from "@Interfaces/enums";
import { ActionConsts, ThrowIfNotAuthenticated, paymentTypeToAnalyticsString } from "@Definitions";
import { CampaignModel, IStore, OfferModel, PaymentModel, IPaymentActions } from "@Interfaces";
import { PaymentService, Analytics } from "@Services";
import { ProfileActions } from "@Actions";
// #endregion Local Imports

export const PaymentActions = {
    // type any would be removed when mock offer ends
    SetSelectedCampaign: (campaign: CampaignModel.Campaign | OfferModel.OfferResponse | any) => (
        dispatch: Dispatch
    ) => {
        dispatch({
            payload: { selectedCampaign: campaign },
            type: ActionConsts.Payment.SelectedCampaign,
        });
    },
    SetSelectedOperator: (operator: string) => (dispatch: Dispatch) => {
        dispatch({
            payload: { selectedOperator: operator },
            type: ActionConsts.Payment.SelectedOperator,
        });
    },
    Subscription: async (
        payload: PaymentModel.SubscriptionPayload,
        paymentSource?: IPaymentActions.SubscriptionSource,
        offerName?: string
    ) => async (dispatch: Dispatch, getState: () => IStore) => {
        const { user } = getState().auth;
        if (!user || !user.UserId) {
            ThrowIfNotAuthenticated(getState);
        }

        // analytics data
        const analyticsData = {
            payment_source: paymentSource,
            payment_type: paymentTypeToAnalyticsString(payload.PaymentMethod),
            sa_offer_name: offerName,
            sa_installment: payload.Installment,
            sa_is3ds: undefined,
        };

        dispatch({
            type: ActionConsts.Payment.Subscriptions.Request,
        });
        try {
            const result = await PaymentService.Subscription(user!.UserId, payload);

            // set membership gold
            if (result.ThreeDHtmlContent === null) {
                ProfileActions.SetMembershipType(MembershipType.gold)(dispatch, getState);

                // non 3d secure payments send analytics
                Analytics.event("Payment Succeed", analyticsData);
            }

            dispatch({
                payload: {
                    subscriptions: {
                        data: result,
                    },
                },
                type: ActionConsts.Payment.Subscriptions.Success,
            });
        } catch (e) {
            const error = await e.json();
            dispatch({
                payload: {
                    subscriptions: {
                        error,
                    },
                },
                type: ActionConsts.Payment.Subscriptions.Failed,
            });
            // send analytics
            Analytics.event("Payment Failed", analyticsData);
        }
    },
    Installment: async (payload: PaymentModel.InstallmentPayload) => async (dispatch: Dispatch) => {
        dispatch({
            type: ActionConsts.Payment.Installment.Request,
        });

        try {
            const result = await PaymentService.Installment(payload);
            dispatch({
                payload: {
                    installment: {
                        data: result.result,
                    },
                },
                type: ActionConsts.Payment.Installment.Success,
            });
        } catch (e) {
            dispatch({
                payload: {
                    installment: {
                        error: "Error",
                    },
                },
                type: ActionConsts.Payment.Installment.Failed,
            });
        }
    },
    MobilePaymentCheck: async (userId: number | string, paymentId: number | string) => async (
        dispatch: Dispatch
    ) => {
        dispatch({
            type: ActionConsts.Payment.MobilePaymentCheck.Request,
        });

        try {
            const result = await PaymentService.MobilePaymentCheck(userId, paymentId);
            dispatch({
                payload: {
                    mobilePaymentCheck: {
                        data: result,
                    },
                },
                type: ActionConsts.Payment.MobilePaymentCheck.Success,
            });
        } catch (e) {
            dispatch({
                payload: {
                    mobilePaymentCheck: {
                        error: "Error",
                    },
                },
                type: ActionConsts.Payment.MobilePaymentCheck.Failed,
            });
        }
    },
};
