// #region Global Imports
import * as React from "react";
import Link from "next/link";
// #endregion Global Imports

// #region Local Imports
import { Image, Title } from "@Components/Basic";
import {
    Container,
    ImageWrapper,
    CardBottom,
    FlexWrapper,
    CardTitle,
    TitleTicks,
    CardDescription,
    CardLink,
    Anchor,
} from "./styled";
import { IHappyCoupleCard } from "./HappyCoupleCard";
// #endregion Local Imports

const HappyCoupleCard: React.FunctionComponent<IHappyCoupleCard.IProps> = ({
    image,
    title,
    description,
    link,
}): JSX.Element => (
    <Container>
        <ImageWrapper>
            <Image src={image} alt="Happy Couple" />
        </ImageWrapper>
        <CardBottom>
            <FlexWrapper>
                <CardTitle>
                    <TitleTicks>“</TitleTicks>
                    <Title fontWeight="bold" size="large">
                        {title}
                    </Title>
                </CardTitle>
                <CardDescription>{description}</CardDescription>
                <CardLink>
                    <a href={link} target="_blank" >
                        <Anchor>Devamını oku &gt;</Anchor>
                    </a>
                </CardLink>
            </FlexWrapper>
        </CardBottom>
    </Container>
);

export { HappyCoupleCard };
