import Router from "next/router";
import { MembershipType } from "@Interfaces/enums";
import { ICampaignActions, IProfilePage, ProfileModel } from "@Interfaces";

export const RedirectPayment = async (source?: ICampaignActions.AnalyticsSource) => {
    await Router.push(
        `/payments/[type]/credit-card/payment?source=${source}`,
        `/payments/gold/credit-card/payment`
    );
};

export const RedirectProfile = async (
    userId: number | string,
    source?: IProfilePage.AnalyticsSource
) => {
    await Router.push(`/profile/[id]?source=${source}`, `/profile/${userId}`);
};
