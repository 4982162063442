// #region Global Imports
import React from "react";
// #endregion Global Imports

// #region Local Imports
import { Text, Small, RadioButton } from "@Components/Basic";
import { Container, Footer, CampaignName, CampaignPrice, Main, Image, HasAdvantage } from "./styled";
// #endregion Local Imports

// #region Interface Imports
import { IGoldPackage } from "./GoldPackage";
// #endregion Interface Imports

export const GoldPackage: React.FunctionComponent<IGoldPackage.IProps> = ({
    t,
    campaign,
    onClick,
    isActive,
    paymentType,
    isBestDeal,
    isSpecialOffer,
    basePrice
}) => {
    const price:number = Number((campaign.DiscountedPrice || campaign.ProductPrice).toString().replace(",",""));
    const calcInstallment = () => {
        if (campaign.ProductDurationUnit * campaign.ProductDuration > 3) {
            const calcMonth:number =
                campaign.ProductDurationUnit >= 4
                    ? campaign.ProductDuration * 12
                    : campaign.ProductDuration;
            return (price / calcMonth).toFixed(2);
        }

        return false;
    };

    const hasAdvantage = () => {
        let hasAdvantage = 0
        if (basePrice) {
            let ratio = 1;
            switch (campaign.ProductDurationUnit) {
                case 4:
                    ratio *= campaign.ProductDuration * 12;
                break;
                case 3:
                    ratio *= campaign.ProductDuration;
                break;
                default:
                    ratio = 0;
                break;
            }

            if (ratio) {
                hasAdvantage = 1 - ((price / ratio) / basePrice);
            }
        }

        return hasAdvantage
    }

    return (
        <Container onClick={onClick} isActive={isActive === true}>
            <Main>
                <div className="w-100 d-md-block d-none">
                    <RadioButton checked={isActive} id="" name="" value="" />
                </div>
                <CampaignName>
                    <Text
                        weight="bold"
                        color={isActive ? "sliderDarkBlue" : "black"}
                        className="name"
                    >
                        {campaign.Name}
                    </Text>
                    <Text
                        weight="bold"
                        color={isActive ? "sliderDarkBlue" : "gray"}
                        size="small"
                        className="mb-3"
                    >
                        {`${campaign.Coin} ${t("common:payment.goldPackage.credit")}`}
                    </Text>
                </CampaignName>
                {isBestDeal && (
                    <Image>
                        <img src="/images/best-deal-tr.png" alt="en iyi fiyat" />
                    </Image>
                )}

                {isSpecialOffer && (
                    <Image>
                        <img src="/images/special-offer-tr.png" alt="size özel" />
                    </Image>
                )}
                {
                    hasAdvantage() > 0 && (
                        <HasAdvantage className={isSpecialOffer || isBestDeal ? 'double' : ''} title="Yaklaşık İndirim Oranı">
                            <b>%{Math.ceil(hasAdvantage() * 100)}</b>
                            <span>
                                {t("common:payment.goldPackage.discount")}
                            </span>
                        </HasAdvantage>
                    )
                }
                {campaign.Installment?.length > 0 && (
                    <div className="d-md-flex d-none flex-column align-items-center">
                        <Text
                            weight="bold"
                            color={isActive ? "sliderDarkBlue" : "gray"}
                            size="small"
                        >
                            {t("common:payment.goldPackage.monthly")}
                        </Text>
                        <Text
                            weight="regular"
                            color={isActive ? "sliderDarkBlue" : "gray"}
                            className="fs-30"
                        >
                            {`${calcInstallment()} TL`}
                        </Text>
                    </div>
                )}

                <CampaignPrice>
                    <Text weight="bold" color={isActive ? "sliderDarkBlue" : "black"}>
                        {`${t("common:payment.goldPackage.total")}: `}
                        <span
                            className={
                                campaign.ProductPrice !== campaign.DiscountedPrice ? "draw" : ""
                            }
                        >
                            {`${campaign.ProductPrice} TL`}
                        </span>
                    </Text>
                    {campaign.ProductPrice !== campaign.DiscountedPrice && (
                        <Text weight="bold" color={isActive ? "sliderDarkBlue" : "black"}>
                            {`${t("common:payment.goldPackage.instead")}: `}
                            {`${campaign.DiscountedPrice} TL`}
                        </Text>
                    )}
                </CampaignPrice>
            </Main>
            <Footer
                className={
                    !campaign.Installment ||
                    !campaign.Installment.length ||
                    paymentType !== "creditCard"
                        ? "hidden"
                        : ""
                }
            >
                <img src="/images/payment-installment-cards-1.png" alt="credit-cards" />
                <img src="/images/payment-installment-cards-2.png" alt="credit-cards" />
                <Small size="small">{t("common:payment.goldPackage.installments")}</Small>
            </Footer>
        </Container>
    );
};
